a.DC-mapImg {
  position: absolute;
  background: #fff;
  border: 1px solid #BDBDBD;
  padding: 5px;
  left: 2%;
  margin-top: 10px;
  width: 200px;
  height: 248px;
}
a.DC-mapImg > img.DC-Mapimage {
  width: 100%;
}
  svg.right-arrowSvg {
    font-size: 46px;
    line-height: 52px;
    font-family: 'GrueneType';
    font-weight: 500;
    fill: #fff;
    height: .8em;
    min-width: 31px;
    margin-left: 20px;
  }

  svg.right-arrowSvgMini {
    font-size: 29px;
    line-height: 52px;
    font-family: 'GrueneType';
    font-weight: 500;
    fill: #fff;
    height: 0.5em;
    min-width: 24px;
    margin-left: 20px;
  }

  .mobile-part,
  #mobile-view-btns {
    display: none
  }

  #content p {
    font-family: 'PTSans' !important;
    line-height: 1.5 !important;
    font-size: 18px;
    font-weight: 400;
  }

  #PageInformation {
    display: none;
  }

  .red_block {
    background: #008939;
    text-align: center;
    font-size: 16px;
    padding: 20px;
    margin-top: 30px;
  }

  .red_block a {
    color: #fff !important;
    font-family: 'PTSans';
    font-size: 23px;
    line-height: 31px;
    font-weight: 700;
  }

  .red_block i.icon-arrow-right {
    margin-left: 16px;
    vertical-align: bottom;
  }

  .red_block img.white_img-arrow {
    width: 16px;
    height: auto;
    margin-left: 10px;
  }

  h1.privacypageTitle {
    font-size: 52px !important;
    font-weight: 500;
    font-family: 'GrueneType';
    color: var(--secondary-color) !important;
  }

  ul.scrollToBtns {
    list-style: none !important;
    display: flex;
    gap: 12px;
    margin: 35px auto !important;
    width: 100%;
    padding: 0;
    justify-content: center;
  }

  ul.scrollToBtns li {
    list-style: none !important;
    background: #008939;
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    list-style: none !important;
    color: #FFF;
    font-size: 16px !important;
    font-style: normal;
    cursor: pointer;
    width: 36%;
  }

  ul.scrollToBtns li a {
    display: block;
    padding: 10px 20px;
    text-align: center;
    background: #008939;
    color: #ffffff !important;
    font-family: 'PTSans';
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  ul.scrollToBtns li a:hover {
    background: #005437;
    color: #ffffff !important;
  }
  ul.scrollToBtns li > a:not([href]):not([class]):hover {
    color: #fff !important;
}
  ul.scrollToBtns li a i.icon-arrow-right {
    margin-left: 15px;
  }

  img.AnleitungMobileImg {
    display: none;
  }

  #AnleitungBriefwahl-1 h1,
  #AnleitungBriefwahl-2 h1{
    font-family: 'GrueneType';
    font-size: 40px !important;
    font-weight: 100 !important;
  }

  #AnleitungBriefwahl-1 h3,
  #AnleitungBriefwahl-2 h3{
    font-family: 'GrueneType';
    font-size: 23px !important;
    font-weight: 200 !important;
  }

  #AnleitungBriefwahl-1 .banner,
  #AnleitungBriefwahl-2 .banner{
    background: #008939;
    color: #fff;
    padding: 10px 15px;
    font-family: 'GrueneType';
    font-size: 18px !important;
    font-weight: 200;
    letter-spacing: 0.5px;
    margin-left: 40px;
    cursor: pointer;
  }

  #AnleitungBriefwahl-1 .banner img.white_img-arrow,
  #AnleitungBriefwahl-2 .banner img.white_img-arrow{
    float: right;
    width: 17px;
    height: auto;
    margin-top: 5px;
  }

  #AnleitungBriefwahl-1 ul.banner-list,
  #AnleitungBriefwahl-2 ul.banner-list{
    list-style: none !important;
    margin: 25px auto;
    color: #274A39;
    font-size: 18px;
    font-family: 'PTSans';
  }

  #AnleitungBriefwahl-2 ul.banner-list li img.attention-imgicon,
  #AnleitungBriefwahl-2 ul.banner-list li img.download-imgicon{
    margin-right: 20px;
  }

  div#box1content .modal-dialog.modal-lg,
  div#box2content .modal-dialog.modal-lg {
    width: 850px;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .ms-wikicontent.ms-rtestate-field {
    padding-right: 0px !important;
  }

  #visualization>div>div:first-child {
    width: 100% !important;
    text-align: center;
  }

  li.google-visualization-tooltip-item:last-child {
    white-space: nowrap;
  }

  #table-scroll table {
    border: 1px solid #c9c9c9;
  }


  #stateslist,
  #Stoerer_Briefwahl_imgDiv {
    width: 19%;
    float: left;
    text-align: center;
    margin: 0 auto;
    padding-left: 2rem !important;
  }

  img.Stoerer_Briefwahl_img {
    width: 70%;
    margin: 20px auto;
  }

  .state-list-text {
    padding: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #fff !important;
    font-family: 'PTSans' !important;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  .pagetitle {
    font-size: 17px;
    font-weight: 400;
    padding: 0 0 20px 15px;
    font-family: Rockwell;
    color: #4a4a4a !important;
  }

  #suiteBar,
  #s4-ribbonrow,
  #SearchBox {
    display: none !important;
  }

  #stateslist>li a {
    color: #ffffff !important;
    text-decoration: none;
  }

  .Briefwahl2021 #stateslist li {
    border: 1px solid #ffffff;
    display: inherit;
    font-size: 16px;
    padding: 3px 10px;
    background: #005437 none repeat scroll 0 0;
    font-style: normal !important;
    margin: 3px 0;
  }
#box1content .banner, #box2content .banner {
    background: #008939;
    color: #fff;
    padding: 10px 15px;
    font-family: 'PTSans';
    font-size: 16px !important;
    font-weight: 700;
    margin-left: 40px;
    cursor: pointer;
}
  #stateslist>li:hover, #box1content .banner:hover {
    background: #008939 none repeat scroll 0 0;
    cursor: pointer;
    color: #FFCB00 !important;
  }
  #stateslist>li:hover>a>h3 {
    color: #FFCB00 !important;
}
  /* #stateslist>li:last-child {
    background: #ffffff none repeat scroll 0 0;
    margin-top: 12px;
  } */

  .btn-primary {
    background-color: #008939 !important;
    border-color: #008939  !important;
    /* text-transform: uppercase; */
    font-family: 'PTSans' !important;
    padding: 4px 20px !important;
    font-size: 16px !important;
    border-radius: 0px !important
  }
  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #918d8d !important;
    border-color: #918d8d !important;
}
  .Briefwahl-modal-btn {
    padding-left: 8%;
  }

  #stateslist img {
    width: 160px !important;
    height: 148px !important;
    margin: 0 auto;
  }

  #searchInputBox {
    float: right;
    margin-bottom: 12px;
    display: none;
  }


  /******************** Popup CSS**********************
    ***************************************************/
    div#box1content.modal, div#box2content.modal, div#box3content.modal {
      z-index: 9999;
    }
    div#box1content .modal-content, div#box2content .modal-content, div#box3content .modal-content {
      padding: 24px;
    }
    
    div#box1content .modal-body, div#box2content .modal-body, div#box3content .modal-body {
      padding: 20px 0 0px 0;
      gap: 20px;
    display: flex;
    flex-direction: column;
    }
div#box1content .modal-header h3, div#box2content .modal-header h3, div#box3content .modal-header h3 {
  color: #005437;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  font-family: 'PTSans' !important;
}

div#box1content .modal-header h3 > span.subtext, div#box2content .modal-header h3 > span.subtext, div#box3content .modal-header h3 > span.subtext {
  color: #333;
  font-family: 'PTSans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

div#box1content .modal-header, div#box2content .modal-header, div#box3content .modal-header {
  display:flex;
  align-items: start;
  padding: 0 !important;
}

.flex-20 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}


div#box1content span.flex-steps-text {
  color: #0A321F;
  font-family: 'PTSans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

div#box1content .flex-steps {
  display: flex;
  align-items: center;
  gap: 24px;
}

div#box1content span.flex-step {
  display: flex;
  align-items: center;
  gap: 8px;
}

div#box1content span.flex-steps-icon, div#box1content span.flex-steps-icon>svg {
  width: 32px;
  height: 32px;
  background: var(--primary-color);
  border-radius: 12rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.flex-20-text {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bannerlinks{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
}
.bannerlinks .bannerlinksWithList{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.bannerlinks .bannerlinksWithList > a {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  background: #005437;
  color: #fff;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.bannerlinks .bannerlinksWithList > a:hover {
  color: #fff !important;
}

.bannerlinks .bannerlinksWithList > ul.banner-list {
  margin-bottom: 0;
}

.bannerlinks .bannerlinksWithList > ul.banner-list > li {
  list-style: disc;
}
.flex-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

a.linkWithText {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
}

.flex-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

span.text-danger {
  color: #FC0C0C !important;
}

/* .infoBox-itemBox-item {
  position: relative;
  padding: 8px;
} */
.zipCodeHover {
  display: none;
  position: absolute;
  top: 35px;
  left: 40px;
  background-color: #ffffff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 0.9em;
  color: #333;
  z-index: 1;
  width: auto;
  max-width: 250px;
}

.infoBox-itemBox-item:hover .zipCodeHover {
  display: block;
}

  @media screen and (max-width:992px) {
    img.AnleitungDesktopImg {
      width: 100%;
    }

    h1.privacypageTitle {
      font-size: 42px !important;
    }

    ul.scrollToBtns li {
      width: 100%;
      margin: 5px 0;
      padding: 8px 20px;
    }

    ul.scrollToBtns li a {
      padding: 10px 30px;
      display: block;
    }

    .menu ul li {
      padding: 0 0.50em;
    }

    .menu ul li a {
      font: 700 14px "PTSans", open-sans, verdana, sans-serif;
    }

    .ms-webpart-chrome-vertical {
      display: -moz-deck;
    }

    #stateslist {
      display: inline-block;
      float: none;
      margin: 5px 6px;
      width: auto;
    }

    #stateslist li {
      margin: 3px 0;
      padding: 10px;
    }

    #stateslist>li:last-child {
      display: block;
      text-align: center;
    }

    .Briefwahl-modal-btn .btn-primary {
      width: 100%;
      margin: 4px 0;
    }

    .Briefwahl-modal-btn {
      padding-left: 10%;
      padding-right: 10%;
    }

    .current-topic img {
      float: right;
      height: auto;
      margin: -40px 0;
      width: 600px;
    }
  }

  @media screen and (max-width:766.5px) {

    #content p {
      font-size: 15px;
      margin-bottom: 10px;
    }

    #AnleitungBriefwahl-1 ul.banner-list,
    #AnleitungBriefwahl-2 ul.banner-list {
      font-size: 15px;
    }

    .red_block a {
      font-size: 20px;
    }

    .red_block {
      padding: 10px;
    }

    .red_block i.icon-arrow-right {
      margin-left: 10px;
      font-size: 15px;
    }

    svg.right-arrowSvg {
      font-size: 30px;
      line-height: 42px;
      height: .5em;
      min-width: 27px;
      margin-left: 5px;
    }

    svg.right-arrowSvgMini {
      font-size: 24px;
      line-height: 30px;
      height: 0.5em;
      min-width: 18px;
      margin-left: 0px;
    }

    #Stoerer_Briefwahl_imgDiv {
      width: 100%;
      float: none;
      text-align: center;
      margin: 0 auto;
    }

    #regions_div,
    #BriefwahlTitleDiv {
      width: 100%;
      float: left;
    }

    img.Stoerer_Briefwahl_img {
      width: 50%;
      margin: 0px auto;
    }

    .mobile-part,
    #mobile-view-btns {
      display: block;
    }

    #web-view-btns {
      display: none;
    }

    #AnleitungBriefwahl-1 h1,
    #AnleitungBriefwahl-2 h1 {
      font-size: 32px !important;
    }

    #AnleitungBriefwahl-1 .banner,
    #AnleitungBriefwahl-2 .banner {
      margin-left: 0px;
    }

    h1.privacypageTitle {
      font-size: 32px !important;
    }

    ul.scrollToBtns li:first-child {
      margin-right: 0px;
    }

    ul.scrollToBtns {
     display: block;
     width: 100%;
     padding: 0;
    }

    ul.scrollToBtns li a {
      padding: 10px;
      font-size: 14px;
    }

    ul.scrollToBtns li a i.icon-arrow-right {
      margin-left: 10px;
    }

    #box1content h1,
    #box2content h1 {
      font-size: 26px !important;
    }

    img.AnleitungMobileImg {
      display: block;
      margin: 0 auto;
    }

    #box1content .banner,
    #box2content .banner {
      padding: 10px 20px;
      font-size: 14px !important;
      letter-spacing: 0.5px;
      margin-left: 0px;
    }

    img.AnleitungDesktopImg {
      display: none;
    }

    #box2content ul.banner-list li img.attention-imgicon,
    #box2content ul.banner-list li img.download-imgicon {
      margin-right: 10px;
      width: 25px;
    }

    .ms-siteicon-img {
      display: block;
      height: 70px;
      margin-left: 10px;
    }

    .current-topic img {
      display: block;
      float: right;
      height: 72px;
      width: 350px;
      margin: 0;
    }

    .menu ul li {
      padding: 0 0.55em;
    }

    .menu ul li a {
      font: 700 13px "PTSans", open-sans, verdana, sans-serif;
      margin: 0;
    }

    .menu ul li.small a {
      font: 700 13px "PTSans", open-sans, verdana, sans-serif;
      margin: 0;
    }

    #contentBox {
      min-width: 100%;
    }

    .ms-wikicontent.ms-rtestate-field {
      padding-right: 0px;
    }

    #pageTitle {
      font-size: 17px;
    }

    .ms-webpart-chrome-vertical {
      display: -moz-inline-grid;
      width: 100%;
    }

    #stateslist {
      display: inline-block;
      float: none;
      margin: 0;
      text-align: left;
      width: auto;
      padding: 0 10px;
    }

    .Briefwahl-modal-btn {
      padding-left: 10px;
      padding-right: 10px;
    }

    .Briefwahl-modal-btn>.btn-primary {
      margin-bottom: 10px;
    }

    #regions_div {
      display: none;
    }

    #stateslist img {
      height: auto;
      margin: 0 auto;
    }

    #stateslist li {
      display: inline-block;
      font-size: 16px;
      margin: 2px 3px;
      padding: 7px 10px;
    }

    #stateslist>li:last-child {
      clear: both;
      display: block;
    }
    .Homepage.position-relative {
      width: 100% !important;
      margin: 0 auto;
  }
  .Homepage .CustomSearchInputWithBtn {
    width: 100%;
}
.right-tile-section {
  width: 100%;
}
.ul.HomepagestateListbuttonTiles > li {
    position: relative;
    top: 5px;
    width: 100%;
    right: 0px;
    padding: 0 !important;
}
  }

  /*#regions_div path:hover {fill: #09311d;}
    #regions_div rect:hover {fill:transparent;}*/
