#stateslist,
#Stoerer_Briefwahl_imgDiv {
    float: left;
    margin: 0 auto;
    text-align: center;
    width: 19%;
    padding-left: 2rem !important;
}

.topDesign ul#stateslist {
    width: 100%;
    float: left;
}

.shorticon {
    line-height: 1.1rem;
    position: absolute;
    right: 10px;
    top: 4px;
}

.shorticon .downArrow>svg,
.shorticon .upArrow>svg {
    font-size: 11px;
    line-height: 11px;
    background: #fff;
}
.Briefwahl.SmartTableOnTaskPopup {
    max-height: 450px;
    position: absolute;
    background: #fff;
    top: 52px;
    border: 1px solid #ccc;
    padding-bottom: 6px;
    overflow-y: auto;
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    z-index: 1;
}
.SmartTableOnTaskPopup {
    max-height: 450px;
    position: absolute;
    background: #fff;
    top: 52px;
    border: 1px solid #ccc;
    padding-bottom: 6px;
    overflow-y: auto;
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    z-index: 1;
}
table.SmartTableOnTaskPopupNoResult {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    z-index: 1;
    text-align: center;
    background: #fff;
    border: 1px solid #ccc;
}
.Briefwahl.SmartTableOnTaskPopup{
    position: relative;
    top: 5px;
}
.BriefwahlInformationMiniPopup .SmartTableOnTaskPopup {
    position: relative;
    top: 5px;
}
ul.stateListTiles {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    list-style: none;
    margin: 19px 0 0 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
}

ul.Briefwahl.stateListTiles {
    width: 80%;
    align-items: center;
    gap: 16px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
}
ul.Briefwahl.stateListTiles > li{
   width: calc(25% - 12px)
}

ul.stateListTiles > li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    width: calc(50% - 10px);
    background: #F5F5F5;
    cursor: pointer;
}

ul.stateListTiles > li > img.stateLogo {
    height: 44px;
    width: auto;
}

ul.stateListTiles > li > span.stateName {
    color: #000;
    font-family: 'PTSans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

ul.stateListTiles > li.state {
    width: 100%;
    text-align: center;
    justify-content: center;
}

ul.stateListTiles > li.active {
    background: #008939;
}

ul.stateListTiles > li.active > span.stateName {
    color: #fff;
}
/****** new css homepage ***********/
.left-map-section {
    width: 48%;
    float: left;
    align-content: center;
  align-items: center;
  display: flex;
}
.right-tile-section { 
    width: 52%;
}
/* .Homepage.position-relative {266
    width: 1100px;
} */
.flex-searchrowWithBtn {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 18px;
}
.CustomSearchInputTile {
    width: calc(26% - 16px);
}
.Homepage .SmartTableOnTaskPopup {
    width: 100%;
}
.Homepage .CustomSearchInputWithBtn{
    width: calc(74% - 5px);
    position: relative;
}
ul.HomepagestateListbuttonTiles {
    margin: 0;
    padding: 0;
}
.CustomSearchInputTile > ul.HomepagestateListbuttonTiles > li.state.state.active > img.stateLogo{
    height: 44px;
    padding: 4px;
    object-fit: contain;
}
ul.HomepagestateListbuttonTiles > li.state > span.stateName {
    font-size: 16px;
}
ul.HomepagestateListbuttonTiles > li{
    list-style: none;
    text-align: center;
    justify-content: start;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--primary-color);;
        }
ul.HomepagestateListTiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    justify-content: space-around;
}
ul.HomepagestateListTiles > li{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 284px;
    background: #F5F5F5;
    cursor: pointer;
    color: #FFF;
font-size: 16px !important;
font-style: normal;
font-weight: 400;
line-height: normal;
}
ul.HomepagestateListTiles > li.state {
    text-align: center;
    justify-content: start;
    position: absolute;
    top: 0;
    width: 294px;
    right: 17px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--primary-color);;
}
ul.HomepagestateListTiles > li.states {
    width: calc(50% - 10px);
    gap: 8px;
    color: #000;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
ul.HomepagestateListTiles > li.states.active{
    background: var(--primary-color);;
    color:#fff;
}
ul.HomepagestateListbuttonTiles > li.state.states.active.gray {
    background: #f5f5f5;
    color: #000;
}
span.SearchInputFieldstateLogo {
    position: absolute;
    right: 0;
    cursor: pointer;
}
/****** new css homepage ends ***********/
.CustomSearchInputWithBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.CustomSearchInputWithBtn > button.btn.btn-primary {
    height: 48px;
    padding: 12px 16px !important;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

input.CustomSearchInput {
    display: flex;
    padding: 8px 12px 8px 56px;
    align-items: center;
    gap: 10px;
    flex: 1 0;
    align-self: stretch;
    background: #f5f5f5;
    height: 48px !important;
    border: none;
    margin-bottom: 0;
    border-radius: 0 !important;
    font-weight: 700;
}
input.CustomSearchInput::placeholder {
    color: #555 !important;
}
.CustomSearchInputWithBtn > span.BtnSearchIcon {
    position: absolute;
    left: 18px;
}

.BriefwahlInformationMiniPopup .CustomSearchInputWithBtn > span.BtnSearchIcon{
    left: 12px
  }
.CustomSearchInputWithBtn > span.BtnCrossIcon {
    position: absolute;
    right: 5%;
    display: none; /* Hide the icon by default */
    cursor: pointer; /* Adjust spacing as needed */
  }
  .Briefwahl.CustomSearchInputWithBtn>span.BtnCrossIcon{
    right: 18%;
}

  .CustomSearchInput:not(:placeholder-shown) + .BtnCrossIcon {
    display: inline-block; /* Show the icon when input has a value */
  }
  .BriefwahlInformationMiniPopup .CustomSearchInputWithBtn > span.BtnCrossIcon{
    right: 22%;
  }
.SmartTableOnTaskPopup tr.searchItemList {
    border-bottom: 1px solid #E4E4E4 !important;
    display: flex;
    padding: 6px 12px !important;
    align-items: center;
    gap: 15px;
    width: 100%;
}
ul.HomepagestateListTiles>li {
    height: 46px;
}
ul.HomepagestateListTiles>li>img.stateLogo {
    height: 40px;
    padding: 4px 0px 4px 4px;
    object-fit: contain;
    width: 45px;
}
span.SearchInputFieldstateLogo > img {
    height: 44px;
    padding: 4px;
    object-fit: contain;
}
ul.HomepagestateListbuttonTiles > li.state.states.active.gray > img.stateLogo {
    height: 44px;
    padding: 4px;
    object-fit: contain;
}
ul.HomepagestateListbuttonTiles>li.state.state.active {
    height: 48px;
    cursor: pointer;
}
.Homepage {
    margin: 0 auto;
    width: 90%;
}
span.MaybeIconSvg > svg {
    margin-left: 3px;
    width: 17px;
    height: 17px;
}

span.MaybeIconSvg {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
}

span.OnlineIconSvg > svg {
    margin-left: 2px;
    width: 26px;
    height: 26px;
}

span.OnlineIconSvg {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
}
span.NAIconSvg > svg {
    margin-left: 1px;
    width: 24px;
    height: 24px;
}

span.NAIconSvg {
    display: flex;
    align-items: center;
    width: 23px;
    height: 23px;
}