/* styles.css */

#page-title {
    position: relative;
 padding: 29px 0;
    background-color: #F5F5F5;
    border-bottom: 1px solid #EEE;
}

#page-title .container {
    position: relative;
}

#page-title h1 {
    padding: 0;
    margin: 0;
    line-height: normal;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    font-size: 2rem;
}

#page-title span {
    /* display: block; */
    /* margin-top: 0px; */
    font-weight: 300;
    color: #777;
    font-size: 18px;
}

.breadcrumb {
    position: absolute !important;
    width: auto !important;
    top: 50% !important;
    left: auto !important;
    right: 15px !important;
    margin: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
    font-size: 90%;
    transform: translateY(-50%);
}

.transparent-header + .page-title-parallax .breadcrumb {
    margin-top: 35px !important;
}

.breadcrumb a {
    color: #555;
}

.breadcrumb a:hover {
    color: #145f32;
}

.breadcrumb i {
    width: auto !important;
}

/* Page Title - Dark */
#page-title.page-title-dark {
    background-color: #327b61;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
    border-bottom: none;
    margin-bottom: 15px;
}

#page-title.page-title-dark span {
    color: rgba(255, 255, 255, 1) !important;
}

/* Page Title - Parallax Background */
.page-title-parallax {
    padding: 100px 0;
    text-shadow: none;
    border-bottom: none;
}

#page-title.page-title-parallax h1 {
    font-size: 44px;
    font-weight: 600;
    color: #fff !important;
    margin-bottom: 0px !important;
}

.page-title-parallax {
    background-color: transparent;
    background-attachment: fixed;
    background-position: center !important;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#page-title.page-title-parallax::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #0f0f0f6e;
}

/* Page Title - Mini */
#page-title.page-title-mini {
    padding: 20px 0;
}

#page-title.page-title-mini h1 {
    font-weight: 600;
    font-size: 18px;
    color: #444;
}

#page-title.page-title-mini span {
    display: none;
}

/* Page Title - No Background */
#page-title.page-title-nobg {
    background: transparent !important;
    border-bottom: 1px solid #F5F5F5;
}

/*spinner*/
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #36D7B7;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  #tabs .nav-tabs .nav-link, .ms-Panel-contentInner .ms-Panel-scrollableContent .ms-Panel-content .nav-tabs .nav-link {
    font-size: 18px;
    font-family: 'PTSans';
    color: var(--text-color);
    line-height: 25px;
}
ul.nav-tabs .nav-link {
    font-size: 19px;
    font-family: 'PTSans' !important;
    font-weight: 400;
    line-height: 29px;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.publicationItem {
    padding: 10px 20px;
    border-radius: 3px;
    background: #ffffff;
    clear: both;
    margin-bottom: 20px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, #tabs .nav-tabs .nav-link.active, .ms-Panel-contentInner .ms-Panel-scrollableContent .ms-Panel-content .nav-tabs .nav-link.active, .ms-Panel-contentInner .ms-Panel-scrollableContent .ms-Panel-content .nav-pills .nav-link.active {
    border-radius: 0px;
    color: var(--primary-color) !important;
    font-weight: 400;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, #tabs .nav-tabs .nav-link.active, .ms-Panel-contentInner .ms-Panel-scrollableContent .ms-Panel-content .nav-tabs .nav-link.active, .ms-Panel-contentInner .ms-Panel-scrollableContent .ms-Panel-content .nav-pills .nav-link.active {
    border-radius: 0px;
    color: var(--primary-color) !important;
    font-weight: 400;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: var(--bs-nav-tabs-link-hover-border-color);
    isolation: isolate;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-radius: 0px;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-pills .nav-link:hover {
    color: var(--primary-color) !important;
}
